import React, { useState, useEffect } from "react";
import ExploreSection from "./exploreSection/index";
import CustomizationSection from "./customiztionSection/index";
import CategorySection from "./categorySection";
import NewProducts from "./newProductsSection/index";
import BannerSection from "./bannerSection/index";
import SeoHelmet from "app/layout/seoHelmet";
import useCustomer from "app/state/hooks/customerHooks/useCustomer";
import ModalWrapper from "app/layout/login/modalWrapper";
import { useHistory } from "react-router-dom";
import UpcomingSection from "./upcomingSection/index";

const Home = () => {
  const { location } = useHistory();
  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal(!modal);
  };
  const [activeItem, setActiveItem] = useState("1");
  const toggleTabs = (tab) => {
    if (activeItem !== tab) {
      setActiveItem(tab);
    }
  };
  const { isLoggedIn } = useCustomer();
  useEffect(() => {
    if (location.state) {
      if (location?.state?.from?.pathname === "/reset-password") {
        toggleModal();
      }
    }
  }, []);

  return (
    <React.Fragment>
      <SeoHelmet
        title="Prima Lighting"
        url=""
        description={"Prima Lighting - Home"}
      />
      <BannerSection />
      <NewProducts />
      <CategorySection />
      <CustomizationSection />
      <UpcomingSection />
      <ExploreSection />
      <ModalWrapper
        toggleModal={toggleModal}
        modal={!isLoggedIn && modal}
        activeItem={activeItem}
        toggleTabs={toggleTabs}
      />
    </React.Fragment>
  );
};
export default Home;
