import React from "react";
import { MDBContainer, MDBRow, MDBCol, MDBTypography, MDBBox } from "mdbreact";
import Listing from "./listing";
import { CatWidget } from "./catWidget";

export const Stepped = (props) => {
  const steps = getSteps(props.listingProps, props.stepCats);
  return (
    <>
      <MDBContainer
        fluid
        className="header-padding-top border-bottom stepped-cat-name-wrapper"
      >
        <MDBRow>
          <MDBCol>
            <MDBContainer>
              <MDBRow>
                <MDBCol className="py-5">
                  <MDBTypography tag="h1" className="h4">
                    {props?.toolbarProps?.name || ""}
                  </MDBTypography>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      {steps.map((step, index) => {
        const stepTitleArr = step.text.split("-");
        const stepTitle = stepTitleArr[stepTitleArr.length - 1].trim();
        const cats = step.cats;
        return (
          <MDBContainer
            fluid
            className="border-bottom pb-5 pt-3 stepped-wrapper"
          >
            <MDBContainer>
              <MDBRow>
                <MDBCol size="12" className="py-4 d-flex justify-content-start">
                  <MDBRow className="steps-title-box">
                    <MDBCol className={`d-flex align-items-center`}>
                      <MDBBox className="steps-numbers-box">{index + 1}</MDBBox>
                      <MDBTypography tag="h6" className="h6 step-title">
                        {stepTitle}
                      </MDBTypography>
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
              </MDBRow>
              {step.items.length > 0 && <Listing items={step.items} />}
              <MDBRow className="px-md-5 mx-md-5 my-3">
                {cats.map((cat) => {
                  return (
                    <CatWidget
                      className={"fsaddf"}
                      cat={cat}
                      key={cat?.id}
                      size={6}
                      lg={3}
                      titleTag="h6"
                    />
                  );
                })}
              </MDBRow>
            </MDBContainer>
          </MDBContainer>
        );
      })}
    </>
  );
};

const getSteps = (items, catSteps) => {
  let steps = [];
  items.forEach((item) => {
    if (!item) {
      return;
    }
    let step = "";
    try {
      step = JSON.parse(item?.step)[0];
    } catch (e) {
      console.log(e.message, item);
    }
    if (step) {
      // let step = "";
      let stepExists = false;
      steps.forEach((stepItem) => {
        if (stepItem && step && stepItem.text === step.text) {
          stepExists = true;
        }
      });
      if (step) {
        if (!stepExists) {
          let cats = [];
          if (catSteps) {
            Object.keys(catSteps).forEach((c) => {
              if (step.text.includes(c)) {
                cats.push(...catSteps[c]);
                catSteps[c].added = true;
              }
            });
          }
          steps.push({ ...step, items: [item], cats: [...cats] });
        } else {
          let itemStep = steps.find((stepItem) => {
            return stepItem.text === step.text;
          });

          itemStep.items.push(item);
        }
      }
    }
  });
  Object.keys(catSteps).forEach((cs) => {
    let s = {};
    s.value = 99;
    s.text = "-" + cs;
    s.items = [];
    s.cats = catSteps[cs];
    if (!catSteps[cs].added) {
      steps.push(s);
    }
  });
  steps.sort((a, b) => {
    return a.value - b.value;
  });
  return steps;
};
