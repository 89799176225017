import { stripIgnoredCharacters } from "graphql";

export default function shrinkQuery(fullURL) {
  const url = new URL(fullURL);
  // Read from URL implicitly decodes the querystring
  const query = url.searchParams.get("query");
  if (!query) {
    return fullURL;
  }
  const strippedQuery = stripIgnoredCharacters(query);
  // URLSearchParams.set will use application/x-www-form-urlencoded encoding
  url.searchParams.set("query", strippedQuery);
  return url.toString();
}
export const customFetchToShrinkQuery = (uri, options) => {
  // TODO: add `ismorphic-fetch` or equivalent to avoid this error
  if (typeof global.fetch !== "function") {
    console.error("This environment does not define `fetch`.");
    return () => {};
  }
  const resource = options.method === "GET" ? shrinkQuery(uri) : uri;
  return global.fetch(resource, options);
};
