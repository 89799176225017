import React from "react";
import gql from "graphql-tag";
import GraphQlWrapper from "core/components/graphQlWrapper";
import {
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCardText,
  MDBCardTitle,
  MDBBox,
  MDBTypography,
  MDBLink,
} from "mdbreact";
import placeholder from "app/assets/images/placeholder.png";
import { Link } from "react-router-dom";
import Scrollbar from "react-scrollbars-custom";

const NEW_PRODUCT_QUERY = gql`
  {
    products(filter: { category_id: { eq: "67" } }, sort: { position: ASC }) {
      items {
        name
        sku
        url_key
        image {
          disabled
          label
          position
          url
        }
        categories {
          name
        }
      }
    }
  }
`;

const NewProducts = (props) => {
  const productsData =
    props && props.data && props.data.products && props.data.products.items;
  if (!productsData) return null;

  return (
    <>
      <MDBBox className="section-title d-flex w-100">
        <hr />
        <MDBTypography tag="h1" variant="h5">
          What's New
        </MDBTypography>
        <hr />
      </MDBBox>
      <Scrollbar noScrollY={true} noScrollX={false}>
        {productsData.map((product, index) => {
          return (
            <MDBCard
              key={index}
              className="text-center product-card new-products-home-page"
            >
              <Link to={product && product.url_key}>
                <MDBCardImage
                  className="img-fluid new-products-home-images"
                  src={(product && product.image.url) || placeholder}
                  waves
                  alt={`${product?.image}`}
                />
                <MDBCardBody className="card-body-home-page">
                  <MDBCardTitle>
                    <MDBLink to={product && product.url_key}>
                      {product && product.name}
                    </MDBLink>
                  </MDBCardTitle>
                  <MDBCardText>{product && product.sku}</MDBCardText>
                </MDBCardBody>
              </Link>
            </MDBCard>
          );
        })}
      </Scrollbar>
    </>
  );
};

const NewProductsSectionQueryContainer = (props) => {
  return (
    <GraphQlWrapper
      query={NEW_PRODUCT_QUERY}
      variables={{ id: "new_products", name: "new_products" }}
      saveQuery={true}
    >
      <NewProducts />
    </GraphQlWrapper>
  );
};

export default NewProductsSectionQueryContainer;
