let color = "#e88602";

export default {
  magento_url: 'https://'+ process.env.REACT_APP_MAIN_STORE +'/',
  graphql_url: "graphql",
  media_url: "/pub/media",
  catalog_url: "/catalog/product",
  category_url: "/catalog/category",
  app_url: "https://primalighting.joladev.com/",
  product_options_url: "/catalog/product_options",
  name: "PRIMA",
  name_homepage: "PRIMA",
  root_category_id: 2,
  product_category_id: 2,
  sessionMessageTime: 4000,
  colors: {
    primary: color,
  },
  businessTypes: [
    { label: "Sales Representative", value: 2 },
    { label: "Other", value: 3 },
  ],
  loader: {
    color: "#151521",
    secondaryColor: "#211f44",
    height: 100,
    width: 100,
    radius: 10,
    type: "ThreeDots",
  },
  mediaGallery: {
    nav: "thumbs",
    desktop: {
      width: 800,
      height: 600,
    },
    mobile: {
      width: "auto",
      height: "auto",
    },
  },
  pricing: {
    defaultCurrency: "USD",
  },
  pageSizeOptions: [
    { label: "12", value: 12 },
    { label: "36", value: 36 },
    { label: "92", value: 92 },
  ],
  pageSortOptions: [
    {
      label: "Newest First",
      value: { custitemsort_date: "DESC",  name: "ASC" },
    },
    {
      label: "Oldest First",
      value: { custitemsort_date: "ASC",  name: "ASC" },
    },
    {
      label: "Name A - Z",
      value: {  custitemsort_date: undefined,name: "ASC" },
    },
    {
      label: "Name Z - A",
      value: {  custitemsort_date: undefined, name: "DESC" },
    },
  ],
};

export const storage = {
  local_storage_key: "prima",
  throttle_local_storage_time: 200,
};
