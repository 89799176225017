import React, {useEffect} from "react";
import {
  MDBCol,
  MDBInputGroup,
  MDBTypography,
  MDBInput,
  MDBBtn,
  MDBBox
} from "mdbreact";
import PrimaSpinner from "app/layout/primaSpinner";
import MaskedInput from "react-text-mask";
import { Controller } from "react-hook-form";

const Form = ({
  name,
  email,
  company,
  phone,
  message,
  onNameChangeHandler,
  onEmailChangeHandler,
  onCompanyChangeHandler,
  onPhoneChangeHandler,
  onMessageChangeHandler,
  hasNameError,
  hasEmailError,
  nameErrorMsg,
  emailErrorMsg,
  submit,
  loading,
  successMessage,
  cleanUpForm,
  telephoneMask,
  control,
  formId
}) => {

  let empty = true;
  const submitFunction = (evt) => {
    evt.preventDefault();
    submit({
      variables: {
        fullname: name,
        company: company,
        telephone: phone,
        email: email,
        message: message,
      },
    });
    cleanUpForm();
  };

  if (
    name === "" ||
    email === "" ||
    company === "" ||
    phone === "" ||
    message === ""
  ) {
    empty = true;
  } else {
    empty = false;
  }

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    script.async = 'async';
    document.body.appendChild(script);
    
    script.addEventListener('load', () => {
        // @TS-ignore
        if (window.hbspt) {
            // @TS-ignore
            window.hbspt.forms.create({
                portalId: "44600176",
                target: '#hubspotForm',
                formId: formId !== undefined ? formId : "94b846bd-7e82-4299-aed9-0e738255be88",
                region: 'na1'
            });
        }
    });
}, []);

  return (
    <div id="hubspotForm" />
    // <form onSubmit={submitFunction} className="contact-form-core">
    //   <MDBCol style={{ marginBottom: "25px" }} className="p-0">
    //     <MDBInputGroup
    //       value={name}
    //       onChange={onNameChangeHandler}
    //       required={true}
    //       material
    //       hint="Enter Name *"
    //       label="Name"
    //       labelClassName={
    //         hasNameError ? `input-label  error-label` : `input-label `
    //       }
    //       className={`input-group-contact ${hasNameError && `error-input`}`}
    //       group
    //       type="text"
    //     />
    //     {hasNameError && (
    //       <MDBTypography colorText="red">{nameErrorMsg}</MDBTypography>
    //     )}
    //   </MDBCol>
    //   <MDBCol className="p-0" style={{ marginBottom: "25px" }}>
    //     <MDBInputGroup
    //       value={email}
    //       onChange={onEmailChangeHandler}
    //       required={true}
    //       material
    //       hint="Enter Email *"
    //       label="Email"
    //       labelClassName={
    //         hasEmailError ? `input-label  error-label` : `input-label `
    //       }
    //       className={`input-group-contact ${hasNameError && `error-input`}`}
    //       group
    //       type="email"
    //     />
    //     {hasEmailError && (
    //       <MDBTypography colorText="red">{emailErrorMsg}</MDBTypography>
    //     )}
    //   </MDBCol>
    //   <MDBCol style={{ marginBottom: "25px" }} className="p-0">
    //     <MDBInputGroup
    //       value={company}
    //       onChange={onCompanyChangeHandler}
    //       material
    //       hint="Enter Company Name *"
    //       label="Company"
    //       labelClassName={`input-label `}
    //       className="input-group-contact"
    //       group
    //       type="text"
    //     />
    //   </MDBCol>
    //   <MDBCol className="p-0" style={{ marginBottom: "40px" }}>
    //     <MDBBox className={`md-form`}>
    //     <Controller
    //             name="GeneralInfo.name"
    //             as={({ value, onChange, onBlur }) => (
    //               <MaskedInput
    //                 value={phone}
    //                 mask={telephoneMask}
    //                 label="Telephone"
    //                 material
    //                 success="right"
    //                 style={{ width: "100%" }}
    //                 placeholder="Enter Phone Number *"
    //                 hint="Enter Telephone*"
    //                 className="form-control input-group-contact"
    //                 onBlur={onPhoneChangeHandler}
    //                 guide={false}
    //                 type="text"
    //               />
    //             )}
    //             control={control}
    //           />
    //           <label
    //             for={`phone`}
    //             className={'input-label active'}
    //           >
    //             Phone
    //     </label>
    //     </MDBBox>
    //   </MDBCol>
    //   <MDBCol className="p-0" style={{ marginBottom: "40px" }}>
    //     <MDBInput
    //       value={message}
    //       onChange={onMessageChangeHandler}
    //       className="input-group-contact"
    //       type="textarea"
    //       label="Message"
    //       rows="7"
    //       placeholder="Enter your message *"
    //     />
    //   </MDBCol>
    //   {loading ? (
    //     <MDBCol style={{ margin: "auto", width: "100%", textAlign: "center" }}>
    //       <PrimaSpinner />
    //     </MDBCol>
    //   ) : (
    //     <MDBCol className="p-0">
    //       <MDBBtn
    //         size="lg"
    //         type="submit"
    //         className="contact-button"
    //         disabled={empty}
    //       >
    //         Send Message
    //       </MDBBtn>
    //       <MDBTypography className="success-message-contact-page" tag="p">
    //         {successMessage}
    //       </MDBTypography>
    //     </MDBCol>
    //   )}
    // </form>
  );
};

export default Form;