import React, { useState, memo, useEffect } from "react";
import CategoryQueryContainer from "./query-container";
import Config from "config/themeConfiguration";
import SeoHelmet from "app/layout/seoHelmet";
import categories from "generation/categories.json";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";

const CategoryStateContainer = memo((props) => {
  const location = useLocation();

  const history = useHistory();
  const action = history.action;
  let search = queryString.parse(history.location.search);
  const [
    locationChangesCounterForReloadPage,
    setLocationChangesCounterForReloadPage,
  ] = useState(0);

  let [pageSize, setPageSize] = useState(Config.pageSizeOptions[0].value);
  let [currentPage, setCurrentPage] = useState(1);

  const [sortSelected, setSortSelected] = useState({
    custitemsort_date: "DESC",
    name: "ASC",
  });

  const queryProps = {
    pageSize,
    currentPage,
    categoryId: props?.category?.id || 2,
  };
  const catList = categories.default.children;

  const handleSetPageSize = (pageSize) => {
    setPageSize(pageSize);
  };

  const toolbarProps = {
    name: props?.category?.name || "",
    pageSize,
    setPageSize: handleSetPageSize,
    currentPage,
    setCurrentPage,
    pageSizeOptions: Config.pageSizeOptions,
    pageSortOptions: Config.pageSortOptions,
    sortSelected,
    setSortSelected,
  };
  const listingProps = { items: [] };
  const catProps = {
    isLanding: props?.category?.is_landing,
    isStepped: props?.category?.is_stepped,
    children: props?.category?.children,
  };

  const displayProps = {
    queryProps,
    toolbarProps,
    listingProps,
    catProps,
    catList,
    sortSelected,
    setSortSelected,
  };
  return (
    <>
      <SeoHelmet
        title={props?.category?.name}
        url={props?.category?.url_path}
        description={props?.category?.description || props?.category?.name}
      />
      <CategoryQueryContainer key={sortSelected} {...displayProps} />
    </>
  );
});

export default CategoryStateContainer;
