import React, { useEffect, useState, useMemo } from "react";
import Product from "./product";
import { isUndefined, isEmpty } from "core/helpers/functions";
import useCache from "app/state/hooks/cacheHooks/useCache";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";

const CUSTOM_ATTRIBUTE_BUBA_ELIGIBLE = gql`
  query {
    customAttributeMetadata(
      attributes: [
        { attribute_code: "baba_eligible", entity_type: "catalog_product" }
      ]
    ) {
      items {
        attribute_code
        attribute_options {
          label
          value
        }
      }
    }
  }
`;

const ProductDataContainer = (props) => {
  let { data, items } = props;
  const { saveNewEntry } = useCache();

  useEffect(() => {
    if (
      typeof data.products !== "undefined" &&
      typeof data.products.items !== "undefined"
    ) {
      if (data?.products?.items?.length > 0) {
        saveNewEntry(
          data,
          `product-${data.products?.items[0]?.url_key?.replace(/ /g, "-")}`
        );
      }
    }
  }, [data, saveNewEntry]);

  // edit functionality
  let editMode = false;
  let editId = false;
  let cartItem = false;

  let product =
    !isUndefined(data) &&
    !isUndefined(data.products) &&
    !isUndefined(data.products.items[0])
      ? data.products.items[0]
      : {
          name: "loading...",
          description: { html: "<p>retrieving information...</p>" },
          price_range: { minimum_price: { final_price: { value: "#" } } },
          loading: true,
        };

  const { data: customAttributeData } = useQuery(
    CUSTOM_ATTRIBUTE_BUBA_ELIGIBLE,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
      skip: !product.baba_eligible,
    }
  );
  const babaAttributes =
    customAttributeData?.customAttributeMetadata?.items?.[0]?.attribute_options;

  let assemblySize = 6;
  let relatedSize = 6;
  let showAssemblyRelated = true;
  if (!isUndefined(product)) {
    let hasAssembly =
      !isUndefined(product.assembly_videos) && product.assembly_videos;
    let hasRelated =
      !isUndefined(product.related_products) &&
      !isEmpty(product.related_products) &&
      product.related_products;
    if (hasAssembly && !hasRelated) {
      assemblySize = 12;
      relatedSize = 0;
      showAssemblyRelated = true;
    } else if (hasRelated && !hasAssembly) {
      relatedSize = 12;
      assemblySize = 0;
      showAssemblyRelated = true;
    } else if (!hasRelated && !hasAssembly) {
      showAssemblyRelated = false;
    }
  }

  const [selectorContent, setSelectorContent] = useState("");

  // edit functionality
  let { location } = window;
  let { search } = location;
  if (search !== "") {
    if (search.includes("edit")) {
      editId = search.split("?edit=");
      if (typeof editId[1] !== undefined) {
        editId = editId[1];
        cartItem = items.find(
          (x) => x.id === editId && x.product.sku === product.sku
        );
        editMode = true;
        if (cartItem === undefined) {
          cartItem = false;
        }
      } else {
        editId = 0;
      }
    }
  }

  let stateProps = {
    assemblySize,
    relatedSize,
    showAssemblyRelated,
    selectorContent,
    setSelectorContent,
  };

  const updatedData = useMemo(() => {
    if (!product.baba_eligible) return product;
    const attributes = product.baba_eligible.split(",");
    return {
      ...product,
      baba_eligible: babaAttributes
        ?.reduce((acc, obj) => {
          if (attributes.includes(obj.value)) {
            acc.push(obj.label.split(" ")[0]);
          }
          return acc;
        }, [])
        .join(", "),
    };
  }, [product, babaAttributes]);

  return (
    <Product
      {...props}
      data={updatedData}
      stateProps={stateProps}
      editId={editId}
      editMode={editMode}
      cartItem={cartItem}
      cartItems={items}
    />
  );
};

export default ProductDataContainer;
