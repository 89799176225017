import React from "react";
import ProductDataContainer from "./data-container";
import { useQuery } from "@apollo/react-hooks";
import { FETCH_PRODUCT, SKU_EXCEPTION_QS } from "./queries";
import Skeleton from "react-loading-skeleton";
import { useMediaQuery } from "react-responsive";
import products from "generation/products.json";
import { withRouter } from "react-router-dom";
import useCustomer from "../../../state/hooks/customerHooks/useCustomer";

const ProductQueryContainer = (props) => {
  const { productSku } = props;

  const isMobile = useMediaQuery({ query: `(max-width: 767px)` });
  const isDesktop = useMediaQuery({
    query: `(min-width: 1024px)`,
  });

  const { isLoggedIn } = useCustomer();

  const {
    data: productData,
    loading: productLoading,
    error: productError,
    refetch,
  } = useQuery(FETCH_PRODUCT, {
    variables: { sku: productSku },
    fetchPolicy: "no-cache",
  });

  if (isLoggedIn) {
    refetch();
  }

  const {
    data: seData,
    loading: seloading,
    error: seError,
  } = useQuery(SKU_EXCEPTION_QS, {
    fetchPolicy: "cache-and-network",
  });

  if (productLoading) return "Loading...";
  if (productError) return `Error! ${productError.message}`;
  if (seloading) return "Loading...";
  if (seError) return `Error! ${seError.message}`;

  const se = seData?.getSkuExceptions || [];

  const prodData = productData?.products?.items?.[0];

  let data = { products: { items: [prodData] } };

  if (productLoading) {
    if (isMobile) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "10rem",
          }}
        >
          <Skeleton width={"100%"} height={"4rem"} count={1} />
          <Skeleton width={"100%"} height={"20rem"} count={1} />
          <Skeleton width={"100%"} height={"4rem"} count={1} />
          <Skeleton width={"100%"} height={"4rem"} count={1} />
          <Skeleton width={"100%"} height={"3rem"} count={1} />
          <Skeleton width={"100%"} height={"3rem"} count={1} />
          <Skeleton width={"100%"} height={"5rem"} count={6} />
        </div>
      );
    }
    if (!isDesktop && !isMobile) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "10rem",
          }}
        >
          <Skeleton width={"100%"} height={"4rem"} count={1} />
          <Skeleton width={"100%"} height={"50rem"} count={1} />
          <Skeleton width={"100%"} height={"6rem"} count={1} />
          <Skeleton width={"100%"} height={"4rem"} count={1} />
          <Skeleton width={"100%"} height={"8rem"} count={5} />
          <Skeleton width={"100%"} height={"4rem"} count={1} />
          <Skeleton width={"100%"} height={"8rem"} count={1} />
        </div>
      );
    }
    if (isDesktop) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            marginTop: "17rem",
            marginBottom: "5rem",
            alignItems: "center",
          }}
        >
          <div style={{ width: "35%" }}>
            <Skeleton width={"100%"} height={"6rem"} count={2} />
            <Skeleton width={"100%"} height={"4rem"} count={1} />
            <Skeleton width={"100%"} height={"6rem"} count={1} />
            <Skeleton width={"100%"} height={"4rem"} count={1} />
            <Skeleton width={"100%"} height={"8rem"} count={5} />
            <Skeleton width={"100%"} height={"4rem"} count={1} />
            <Skeleton width={"100%"} height={"8rem"} count={1} />
          </div>
          <div style={{ width: "50%" }}>
            <Skeleton width={"100%"} height={"80rem"} />
          </div>
          <div style={{ width: "10%" }}>
            <Skeleton width={"100%"} height={"10rem"} count={3} />
          </div>
        </div>
      );
    }
  }

  if (productError) {
    console.warn(productError);
  }

  return <ProductDataContainer {...props} data={data} skuExceptions={se} />;
};

export default withRouter(ProductQueryContainer);
