import React, { useState } from "react";
import { MDBRow, MDBCol, MDBContainer } from "mdbreact";
import useCustomer from "app/state/hooks/customerHooks/useCustomer";
import CheckButton from "core/components/checkButton";
import { isUndefined } from "core/helpers/functions";
import Button from "app/core/components/button";

const NewsletterSubscriptionContent = (props) => {
  const { customer } = useCustomer();
  let [newsletter, setNewsletter] = useState(customer?.is_subscribed);
  return (
    <MDBContainer fluid className="m-0 p-0">
      <MDBCol size={12} className="dashboard-title d-flex align-items-center">
        <h1 className="main-title-account address-main-title-margins h3">
          Newsletter Subscription
        </h1>
      </MDBCol>
      <MDBRow className="m-0 p-0 d-flex align-items-stretch justify-content-center customer-information-main-row">
        <MDBCol size={12}>
          <div className="mb-5">
            <h6 className="mt-5 mb-5 main-title-account h6">
              Subscription Option
            </h6>
          </div>
          <CheckButton
            type={"checkbox"}
            checked={newsletter}
            value={`newsletter`}
            label={"General subscription"}
            onChange={() => {
              setNewsletter(!newsletter);
            }}
          />
          <Button
            className={" mt-5"}
            direction={"mr-2"}
            leftIcon={true}
            disabled={props.locked}
            text={<span>{props.locked ? "Saving..." : "Save"} </span>}
            onClick={(e) => {
              e.preventDefault();
              if (isUndefined(props.locked) || props.locked === false) {
                props.onSubmit({
                  is_subscribed: newsletter,
                });
              }
            }}
          >
            {props.locked ? "Saving..." : "Save"}
          </Button>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default NewsletterSubscriptionContent;
