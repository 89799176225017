import React, { useState } from "react";
import FaqSidebar from "./sidebar";
import { MDBRow, MDBCol } from "mdbreact";
import Listing from "./listing";
import Skeleton from "react-loading-skeleton";
import SeoHelmet from "app/layout/seoHelmet";

const FaqPage = ({ getFaq, loading, error }) => {
  const [selectedCategory, setSelectedCategory] = useState(0);
  return (
    <MDBRow className={`faq-page ml-0 mr-0`}>
      <SeoHelmet
        title="FAQ"
        url={"/faq"}
        description={"Frequently Asked Questions - FAQ"}
      />
      <MDBCol xl="3" className="faq-sidebar-wrapper px-0">
        {loading ? (
          <Skeleton height={`50rem`} />
        ) : !error ? (
          <FaqSidebar
            getFaq={getFaq}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
          />
        ) : (
          <></>
        )}
      </MDBCol>
      <MDBCol xl="9" className="faq-pages-wrapper">
        {loading ? (
          <Skeleton height={`50rem`} />
        ) : !error ? (
          <Listing getFaq={getFaq} selectedCategory={selectedCategory} />
        ) : (
          <div style={{ minHeight: "100rem" }}>
            FAQ information is currently unavailable. Please try again later.
          </div>
        )}
      </MDBCol>
    </MDBRow>
  );
};

export default FaqPage;
