import React, { useReducer } from "react";
import { useMutation } from "react-apollo-hooks";
import { CONTACT_US } from "app/core/components/contact";
import { initialState, reducer } from "app/core/components/contact/store";
import { MDBCol, MDBContainer } from "mdbreact";
import {
  setEmail,
  setName,
  setCompany,
  setPhone,
  setMessage,
} from "app/core/components/contact/store/actions.jsx";
import ContactForm from "app/core/components/contact/contactForm";
import CmsBlock from "app/layout/cmsBlock";
import Icon from "app/assets/icon/icon";
//TODO refactor this
const Form = ({ formId }) => {
  const [store, dispatch] = useReducer(reducer, initialState);
  const onEmailChangeHandler = (e) => setEmail(dispatch, e.target.value);
  const onNameChangeHandler = (e) => setName(dispatch, e.target.value);
  const onCompanyChangeHandler = (e) => setCompany(dispatch, e.target.value);
  const onPhoneChangeHandler = (e) => setPhone(dispatch, e.target.value);
  const onMessageChangeHandler = (e) => setMessage(dispatch, e.target.value);
  const cleanUpForm = () => {
    setEmail(dispatch, "");
    setName(dispatch, "");
    setCompany(dispatch, "");
    setMessage(dispatch, "");
    setPhone(dispatch, "");
  };

  let successMessage = "";
  const [submit, { loading, error, data }] = useMutation(CONTACT_US);
  if (error) return null;
  if (data) {
    successMessage =
      data.contactusFormSubmit.success_message === "Thanks For Contacting Us"
        ? "Thank you for contacting us."
        : "";
  }
  return (
    <MDBCol
      style={{ backgroundColor: "#fbfcf5" }}
      className="form-wrapper-rep-location"
    >
      <ContactForm
        onEmailChangeHandler={onEmailChangeHandler}
        onNameChangeHandler={onNameChangeHandler}
        onCompanyChangeHandler={onCompanyChangeHandler}
        onPhoneChangeHandler={onPhoneChangeHandler}
        onMessageChangeHandler={onMessageChangeHandler}
        email={store.email}
        name={store.name}
        company={store.company}
        phone={store.phone}
        message={store.message}
        hasEmailError={store.emailError}
        hasNameError={store.nameError}
        emailErrorMsg={store.emailErrorMsg}
        nameErrorMsg={store.nameErrorMsg}
        submit={submit}
        loading={loading}
        successMessage={successMessage}
        cleanUpForm={cleanUpForm}
        formId={formId}
        isSalesRepContactForm
      />

      <MDBContainer className="icon-and-text-wrapper">
        <Icon icon={"findARepIcon"} />
        <CmsBlock id={"find_a_rep_contact_us_text"} />
      </MDBContainer>
    </MDBCol>
  );
};

export default Form;
