import React from "react";
import CmsPage from "app/pages/cms/";
import SeoHelmet from "app/layout/seoHelmet";
const TechnicalInformation = () => {
  return (
    <>
      <SeoHelmet
        title={"Technical Information"}
        url={"/technical-information"}
        description={"Technical Information"}
      />
      <CmsPage dangHtml={true} id={5} />
    </>
  );
};
export default TechnicalInformation;
