import React from "react";
import { MDBBox, MDBContainer, MDBRow, MDBCol, Typography } from "mdbreact";
import { fragmentContentById } from "utils/fragmentContentById";
import { CmsDataFragment } from "utils/cmsDataFragment";
import SeoHelmet from "../seoHelmet/seoHelmet";

const QuoteRequestThanks = ({ data, contentMap }) => {
  const content = {
    title: fragmentContentById("quote_thanks_title", data, contentMap),
    text: fragmentContentById("quote_thanks_text", data, contentMap),
    continueButton: fragmentContentById(
      "quote_thanks_continue_button",
      data,
      contentMap
    ),
    homeButton: fragmentContentById(
      "quote_thanks_home_button",
      data,
      contentMap
    ),
    image: fragmentContentById("quote_thanks_image", data, contentMap),
  };

  return (
    <MDBContainer
      fluid
      className="thank-you-quote-request-wrapper"
      style={{ marginTop: "5rem" }}
    >
      <MDBRow>
        <SeoHelmet
          title={"Quote Request Thank you"}
          url={"/quote-request-thank-you"}
          description={"Quote Request Thank you"}
        />
        <MDBCol className="main-column">
          <CmsDataFragment content={content.image} />
          <Typography tag="h1">
            <CmsDataFragment content={content.title} />
          </Typography>
          <Typography tag="h3">
            <CmsDataFragment content={content.text} />
          </Typography>
          <MDBRow center>
            <MDBBox md="3" sm="12" className="ml-4 mr-4 mt-3 continue-button">
              <CmsDataFragment content={content.continueButton} />
            </MDBBox>
            <MDBBox md="3" sm="12" className="ml-4 mr-4 mt-3 home-btn">
              <CmsDataFragment content={content.homeButton} />
            </MDBBox>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default QuoteRequestThanks;
