import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";

const FaqSidebar = (props) => {
  let { setSelectedCategory, selectedCategory, getFaq } = props;

  let faqPages = [];
  let faqData = [...getFaq];
  faqData.map((element) => {
    faqPages = [...faqPages, { ...element.mainCategoryInfo }];
    return element;
  });
  let uniqueArray = _.uniqBy(faqPages, "category_id");

  useEffect(() => {
    if (selectedCategory === 0) {
      setSelectedCategory(uniqueArray[0]);
    }
  }, [selectedCategory, setSelectedCategory, uniqueArray]);

  uniqueArray.sort((a, b) => {
    return a.position - b.position;
  });

  return (
    <div>
      <h1 className="m-0">
        <div className="sr-only">FAQ</div>
      </h1>
      <div className="faq-sidebar">
        <ul className="pl-0">
          {uniqueArray.map((value, index) => {
            let page = value;
            return (
              <Link
                className={`d-flex flex-col faq-sidebar-link ${
                  selectedCategory.category_id === page.category_id
                    ? "active"
                    : ""
                }`}
                key={`faq-category-${page.category_id}`}
                onClick={(e) => {
                  if (typeof page.function !== "undefined") {
                    e.preventDefault();
                  } else {
                    setSelectedCategory(page);
                  }
                }}
              >
                {page.category_title}
              </Link>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default FaqSidebar;
