import React from "react";
import { connect } from "react-redux";
import commonActions from "core/state/redux/data/common/actions";
import SeoHelmet from "app/layout/seoHelmet";
import FindARep from "./find-a-rep-query-container";

const FindARepReduxContainer = (props) => {
  return (
    <>
      <SeoHelmet
        title="Find a Rep"
        url={`/find-a-rep`}
        description={"Find a Rep"}
      />
      <FindARep {...props} />
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    unLock: () => {
      dispatch(commonActions.unlock());
    },
    lock: (callback) => {
      dispatch(commonActions.lock(callback));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FindARepReduxContainer);
