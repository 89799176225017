import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  MDBCol,
  MDBTypography,
  MDBContainer,
  toast,
  ToastContainer,
} from "mdbreact";
import { useMutation } from "react-apollo-hooks";
import { Redirect } from "react-router-dom";
import { ADD_NEW_ADDRESS } from "utils/mutations";
import AddNewAddressForm from "./form";
import useCustomer from "app/state/hooks/customerHooks/useCustomer";
import Countries from "config/data/countries";

const AddNewAddressContent = () => {
  const {
    customer,
    getCountryById,
    getCustomerInformation,
    getTelephoneMaskByCountry,
    getZipMaskByCountry,
  } = useCustomer();
  const { countries } = Countries;

  const defaultRegions = getCountryById("US").available_regions.map(
    (region) => ({
      value: region.id,
      label: region.name,
      code: region.code,
      id: region.id,
      name: region.name,
    })
  );
  const [country, setCountry] = useState({
    label: "United States",
    value: "US",
    available_regions: [...defaultRegions],
  });

  const [state, setState] = useState({ label: "Select State *", value: 0 });
  const [availableStates, setAvailableStates] = useState([...defaultRegions]);

  const onStateChangeHandler = (value) => setState(value);
  let hasStateOrProvince = availableStates?.length > 0;
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [checkedBilling, setCheckedBilling] = useState(false);
  const [checkedShipping, setCheckedShipping] = useState(false);
  const onCountryChangeHandler = (value) => {
    if (value.value === "CA") {
      setState({ value: 0, label: "Select Province" });
    } else if (value.value === "US") {
      setState({ value: 0, label: "Select State" });
    } else if (
      value.value !== "CA" &&
      value.value !== "US" &&
      hasStateOrProvince
    ) {
      setState({ value: 0, label: "Select State/Region" });
    } else {
      setState("");
    }
    let _availableStates = value.available_regions
      ? value.available_regions.map((region) => {
          return {
            value: region.id,
            label: region.name,
            code: region.code,
            id: region.id,
            name: region.name,
          };
        })
      : [];
    setCountry(value);
    setAvailableStates(_availableStates);
  };

  const handleCheckedBilling = () => {
    setCheckedBilling(!checkedBilling);
  };

  const handleCheckedShipping = () => {
    setCheckedShipping(!checkedShipping);
  };

  let telephoneMask = getTelephoneMaskByCountry(country.value);
  let zipMask = getZipMaskByCountry(country.value);

  const { register, handleSubmit, errors, control } = useForm({
    mode: "onSubmit",
    reValidateMode: "onBlur",
    defaultValues: {
      country_code: "",
      default_billing: false,
      default_shipping: false,
      region: "",
      street: [],
    },
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });
  const [stateError, setStateError] = useState(false);
  useEffect(() => {
    if (state.value === 0 && hasStateOrProvince) {
      setStateError(true);
    } else if (state.value === 0 && !hasStateOrProvince) {
      setStateError(false);
    }
  }, [state]);

  const [addNewAddress, { loading }] = useMutation(ADD_NEW_ADDRESS);

  const [nonUsState, setNonUsState] = useState();

  if (shouldRedirect) return <Redirect to="/customer" />;

  return (
    <MDBContainer fluid className="m-0 p-0">
      <MDBCol size={12} className="dashboard-title d-flex align-items-center">
        <MDBTypography
          tag="h1"
          variant="h3"
          className="main-title-account address-main-title-margins"
        >
          Add new address
        </MDBTypography>
      </MDBCol>
      <form
        onSubmit={handleSubmit(async (formData) => {
          let regionInput;
          if (hasStateOrProvince) {
            regionInput = {
              region: state.name ? state.name : formData.state,
              region_code: state.code ? state.code : "",
              region_id: state.id ? state.id : 0,
            };
          } else {
            regionInput = { region: nonUsState };
          }
          if (!stateError) {
            try {
              addNewAddress({
                variables: {
                  city: formData.city,
                  company: formData.company,
                  country_code: country?.value,
                  default_billing: checkedBilling,
                  default_shipping: checkedShipping,
                  firstname: formData.firstname,
                  lastname: formData.lastname,
                  postcode: formData.postcode,
                  region: regionInput,
                  street: [formData.address1, formData.address2],
                  telephone: formData.telephone,
                  address_email: formData.address_email,
                },
              }).then(() => {
                getCustomerInformation();
              });

              toast(
                <MDBTypography style={{ margin: "auto" }}>
                  You successfully added new address!
                </MDBTypography>
              );
              setTimeout(() => setShouldRedirect(true), 3000);
            } catch (error) {
              console.log("!error ", error);
              setShouldRedirect(false);
            }
          }
        })}
      >
        <ToastContainer autoClose={3000} hideProgressBar />
        <AddNewAddressForm
          customer={customer}
          register={register}
          errors={errors}
          countries={countries}
          country={country}
          state={state}
          availableStates={availableStates}
          onCountryChangeHandler={onCountryChangeHandler}
          onStateChangeHandler={onStateChangeHandler}
          handleCheckedBilling={handleCheckedBilling}
          handleCheckedShipping={handleCheckedShipping}
          loading={loading}
          zipMask={zipMask}
          telephoneMask={telephoneMask}
          control={control}
          nonUsState={nonUsState}
          setNonUsState={setNonUsState}
          stateError={stateError}
          setStateError={setStateError}
        />
      </form>
    </MDBContainer>
  );
};

export default AddNewAddressContent;
