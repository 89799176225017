import React, { useReducer } from "react";
import { MDBRow, MDBContainer, MDBCol } from "mdbreact";
import { useMutation } from "react-apollo-hooks";
import gql from "graphql-tag";
import LeftSection from "./leftSection";
import ContactForm from "./contactForm";
import { initialState, reducer } from "./store";
import {
  setEmail,
  setName,
  setCompany,
  setPhone,
  setMessage,
  // setEmailError,
  // setNameError,
  setEmailErrorMsg,
  // setNameErrorMsg,
} from "./store/actions.jsx";
import SeoHelmet from "app/layout/seoHelmet";

export const CONTACT_US = gql`
  mutation ContactUs(
    $fullname: String
    $company: String
    $telephone: String
    $email: String
    $message: String
  ) {
    contactusFormSubmit(
      input: {
        fullname: $fullname
        company: $company
        telephone: $telephone
        email: $email
        message: $message
      }
    ) {
      success_message
    }
  }
`;

const ContactContent = () => {
  const [store, dispatch] = useReducer(reducer, initialState);

  const onEmailChangeHandler = (e) => {
    setEmailErrorMsg(dispatch, "");
    setEmail(dispatch, e.target.value);
  };
  const onNameChangeHandler = (e) => setName(dispatch, e.target.value);
  const onCompanyChangeHandler = (e) => setCompany(dispatch, e.target.value);
  const onPhoneChangeHandler = (e) => setPhone(dispatch, e.target.value);
  const onMessageChangeHandler = (e) => setMessage(dispatch, e.target.value);

  // const handleSubmit = () => {
  //   const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  //   if (!store.name) {
  //     setNameError(dispatch, true);
  //     setNameErrorMsg(dispatch, "This field is required");
  //     return;
  //   } else {
  //     setNameError(dispatch, false);
  //     setNameErrorMsg(dispatch, "");
  //   }
  //   if (!store.email) {
  //     setEmailError(dispatch, true);
  //     setEmailErrorMsg(dispatch, "This field is required");
  //     return;
  //   } else if (!emailRegex.test(store.email)) {
  //     setEmailError(dispatch, true);
  //     setEmailErrorMsg(dispatch, "Invalid email");
  //     return;
  //   } else {
  //     setEmailError(dispatch, "");
  //     setEmailErrorMsg(dispatch, "");
  //     setEmail(dispatch, "");
  //     setName(dispatch, "");
  //     setCompany(dispatch, "");
  //     setMessage(dispatch, "");
  //     setPhone(dispatch, "");
  //   }
  // };

  const cleanUpForm = () => {
    setEmail(dispatch, "");
    setName(dispatch, "");
    setCompany(dispatch, "");
    setMessage(dispatch, "");
    setPhone(dispatch, "");
  };

  let successMessage = "";
  const [submit, { loading, error, data }] = useMutation(CONTACT_US);
  if (error) return null;
  if (data) {
    successMessage =
      data.contactusFormSubmit.success_message === "Thanks For Contacting Us"
        ? "Thank you for contacting us."
        : "";
  }

  const handleSubmit = async (data) => {
    try {
      if (
        /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(data?.variables?.email) ===
        false
      ) {
        setEmailErrorMsg(dispatch, "Invalid email");
      } else {
        const result = await submit({
          variables: data?.variables,
        });
        if (result?.data?.contactusFormSubmit?.success_message) {
          cleanUpForm();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // console.log(data, loading, error)
  return (
    <MDBContainer
      fluid
      style={{
        background: "linear-gradient(to bottom, #160f47, #0f1024)",
        paddingTop: "17rem",
        minHeight: "900px",
      }}
    >
      <SeoHelmet
        title={"Contact us"}
        url={"/contact-us"}
        description={"Contact us"}
      />
      <MDBRow style={{ minHeight: "900px" }}>
        <MDBCol lg={"8"}>
          <LeftSection />
        </MDBCol>
        <MDBCol
          lg={"4"}
          style={{ backgroundColor: "#fbfcf5", marginTop: "-6.6em" }}
        >
          <ContactForm
            onEmailChangeHandler={onEmailChangeHandler}
            onNameChangeHandler={onNameChangeHandler}
            onCompanyChangeHandler={onCompanyChangeHandler}
            onPhoneChangeHandler={onPhoneChangeHandler}
            onMessageChangeHandler={onMessageChangeHandler}
            email={store.email}
            name={store.name}
            company={store.company}
            phone={store.phone}
            message={store.message}
            hasEmailError={store.emailErrorMsg}
            hasNameError={store.nameError}
            emailErrorMsg={store.emailErrorMsg}
            nameErrorMsg={store.nameErrorMsg}
            submit={handleSubmit}
            loading={loading}
            successMessage={successMessage}
            cleanUpForm={() => {}}
          />
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default ContactContent;
